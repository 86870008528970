@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="range"].slider {
  -webkit-appearance: none;
  width: 100%;
  margin: 0px 0;
}
input[type="range"].slider:focus {
  outline: none;
}
input[type="range"].slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  box-shadow: 0.2px 0.2px 0px #000000, 0px 0px 0.2px #0d0d0d;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type="range"].slider::-webkit-slider-thumb {
  box-shadow: 0.8px 0.8px 1.9px rgba(0, 0, 62, 0.67),
    0px 0px 0.8px rgba(0, 0, 88, 0.67);
  border: 1.9px solid rgba(0, 30, 0, 0.57);
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: #22292f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
}
input[type="range"].slider:focus::-webkit-slider-runnable-track {
  background: #ffffff;
}
input[type="range"].slider::-moz-range-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  box-shadow: 0.2px 0.2px 0px #000000, 0px 0px 0.2px #0d0d0d;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type="range"].slider::-moz-range-thumb {
  box-shadow: 0.8px 0.8px 1.9px rgba(0, 0, 62, 0.67),
    0px 0px 0.8px rgba(0, 0, 88, 0.67);
  border: 1.9px solid rgba(0, 30, 0, 0.57);
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: #22292f;
  cursor: pointer;
}
input[type="range"].slider::-ms-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"].slider::-ms-fill-lower {
  background: #f2f2f2;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0.2px 0.2px 0px #000000, 0px 0px 0.2px #0d0d0d;
}
input[type="range"].slider::-ms-fill-upper {
  background: #ffffff;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0.2px 0.2px 0px #000000, 0px 0px 0.2px #0d0d0d;
}
input[type="range"].slider::-ms-thumb {
  box-shadow: 0.8px 0.8px 1.9px rgba(0, 0, 62, 0.67),
    0px 0px 0.8px rgba(0, 0, 88, 0.67);
  border: 1.9px solid rgba(0, 30, 0, 0.57);
  width: 16px;
  border-radius: 4px;
  background: #22292f;
  cursor: pointer;
  height: 16px;
}
input[type="range"].slider:focus::-ms-fill-lower {
  background: #ffffff;
}
input[type="range"].slider:focus::-ms-fill-upper {
  background: #ffffff;
}
